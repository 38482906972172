'use client';

import { Box, Grid2, CircularProgress } from '@mui/material';

import { useDefaultLanguageRedirect } from '../hooks/useDefaultLanguageRedirect/useDefaultLanguageRedirect';

// Redirect the user to the default locale when `/` is requested
const RootPage = () => {
  useDefaultLanguageRedirect();

  return (
    <Grid2 component="main" size={12}>
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: { xs: 2, md: 4 } }}>
        <CircularProgress />
      </Box>
    </Grid2>
  );
};

export default RootPage;
